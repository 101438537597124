@font-face {
  font-family: "BebasNeue";
  src: url("assets/fonts/BebasNeue-Regular.ttf") format("woff2")
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/Montserrat-Regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('assets/fonts/Montserrat-Bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.font-title {
  font-family: 'BebasNeue', sans-serif;
}

.title {
  font-family: 'BebasNeue', sans-serif;
  font-size: 42px;
  text-align: center;
  line-height: 1;
  letter-spacing: 1px;
  padding-top: 8px;
}

.content-background {
  background-repeat: no-repeat;
  background-image: url('assets/images/anchor.png');
  background-position: top -93px right -225px;
}