.hamburgerLabel {
    width: 40px;
    height: 32px;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
}

.hamburgerLine {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

.hamburgerLine:last-child {
    transform-origin: 0% 100%;
}

.hamburgerCheckbox:checked ~ .hamburgerLabel .hamburgerLine {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

.hamburgerCheckbox:checked ~ .hamburgerLabel .hamburgerLine:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.hamburgerCheckbox:checked ~ .hamburgerLabel .hamburgerLine:last-child {
    transform: rotate(-45deg) translate(1px, -1px);
}

.menu {
    top: 100%;
    transform: translateY(-100%);
}

.menuAppearing {
    transition: .3s;
    transform: translateY(0);
}

.hamburgerCheckbox:checked ~ .menu .menuAppearing {
    transform: translateY(100%);
}